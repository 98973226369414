import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import ApplicationsTexts1 from "../components/applicationsTexts1"
import ApplicationsTexts2 from "../components/applicationsTexts2"
import ApplicationsTexts3 from "../components/applicationsTexts3"
import ApplicationsTexts4 from "../components/applicationsTexts4"
import ApplicationsTexts5 from "../components/applicationsTexts5"
import { Trans, useTranslation } from "react-i18next"
import bg from "../images/staticsvgback.svg"

const Applications = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <Seo title={t("Applications")} />
      <div
        className="py-5 bg-light gap-5 d-flex flex-column"
        style={{
          background: `url(${bg}) no-repeat fixed center`,
          backgroundSize: "contain",
        }}
      >
        <h1 className=" display-1 m-5 p-5 text-center">
          <Trans>Applications</Trans>
        </h1>
        <div style={{ width: "25vw" }} className="text-center"></div>

        <ApplicationsTexts1 />
        <ApplicationsTexts2 />
        <ApplicationsTexts3 />
        <ApplicationsTexts4 />
        <ApplicationsTexts5 />
      </div>
    </Layout>
  )
}

export default Applications

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
