import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Trans } from "react-i18next"
import { Link } from "gatsby"

export default function ApplicationsTexts4() {
  return (
    <div
      className="container border shadow p-5 bg-white"
      style={{ borderRadius: "1rem" }}
    >
      <div className="row pt-5 ">
        <div
          className="col-sm-12 col-lg-6 d-flex flex-wrap 
          justify-content-center align-content-center p-5"
        >
          <StaticImage
            src="../images/istockphoto-1198704116-612x612.jpg"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Market Research"
            style={{ objectFit: "contain" }}
          />
        </div>

        <div className="col-sm-12 col-lg-6 d-flex align-items-center">
          <div className="d-flex flex-column">
            <Trans>
              uhappy? can also be used as an internal tool to measure employee
              satisfaction, thus making the necessary changes in the work
              environment
            </Trans>
            <Link to="https://app.uhappy.gr" className="text-center">
              <button className="btn btn-dark btn-lg shadow m-5">
                <Trans>Create Survey</Trans>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
